import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()(() => ({
	container: {
		width: '100%',
		textAlign: 'center',
		marginLeft: 'auto',
		minHeight: '20px',
		display: 'flex',
		justifyContent: 'center',
	},
	trustpilotStyles: {
		minWidth: '200px',

		[getFromBreakpoint('lg')]: {
			minWidth: '430px',
		},

		'& iframe': {
			height: '40px !important',
			width: '200px !important',

			[getFromBreakpoint('lg')]: {
				height: '20px !important',
				width: '430px !important',
			},
		},
	},
	carouselStyles: {
		minHeight: 'inherit',
	},
}));
